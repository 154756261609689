<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="bDialogConfirmationOfPaid"
    :width="screenWidth + '%'"
    scrollable
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-add-raw-material-purchase-order">
          <p class="title-add-raw-material-purchase-order">
            Confirmación de pago
          </p>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12"
              ><div>
                <p class="text-title">Folio</p>
                <p class="text-information">{{ oItem.sReference }}</p>
              </div></v-col
            >
            <v-col cols="12">
              <div>
                <p class="text-title">Monto final a pagar</p>
                <p class="text-information">
                  $ {{ formatMoneyGlobal(oItem.dTotalAmount) }} MXN
                </p>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="content-text-field-account-name">
                <v-select
                  v-model="sAccountName"
                  :items="itemAccountName"
                  item-text="sName"
                  item-value="sAccountId"
                  label="Nombre de la cuenta financiera activa"
                  no-data-text="No hay datos disponibles"
                  class="global-select"
                  color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)"
                  outlined
                  @change="validateConfirmPaid()"
                  clearable
                >
                  <template slot="label">
                    <span
                      >Nombre de la cuenta financiera activa
                      <span class="important-data"></span
                    ></span>
                  </template>
                </v-select>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="content-question-confirm">
                <v-checkbox
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                  v-model="checkbox"
                >
                  <template v-slot:label>
                    <div>
                      <p class="question-confirm">
                        Acepto la responsabilidad y deseo proseguir a marcar
                        como <br />
                        pagada la orden de compra:
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-global mt-2 mb-6"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              :disabled="!bPaid"
              :loading="bLoading"
              class="global-btn-primary"
              @click="confirmPaid()"
            >
              Confirmar pago
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogConfirmationOfPaid: Boolean,
    oPurchaseOrder: Object,
  },
  data() {
    return {
      screenWidth: 0,
      checkbox: false,
      returnToView: false,
      bAdminGlobal: false,
      bLoading: false,
      bPaid: false,
      sAccountName: null,
      itemAccountName: [],
      oItem: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.close();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 70;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 40;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 30;
      }
    },
    confirmPaid() {
      this.bLoading = true;

      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminOPPayable) {
            const payload = {
                sAccountId: this.sAccountName
              },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.patch(
              `${URI}/api/sp/v1/purchase-orders/payments/${this.$route.params.idOC}`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;
                this.$store.commit("refresher", true);
                this.close();
                this.Success(response.data.message);
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getAccounts() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminOPPayable) {
            DB.get(`${URI}/api/sp/v1/accounts`, {
              params: {},
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
            })
              .then((response) => {
                this.itemAccountName = response.data.results;
                this.itemAccountName.unshift({
                  sName: "Seleccionar tipo de cuenta...",
                  sAccountId: null,
                  disabled: true,
                });
              })
              .catch((error) => {
                  this.bLoadingTable = false;
                  this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    close() {
      this.$emit("setDialogConfirmationOfPaid");
      this.sAccountName = null;
      this.bPaid = false;
      this.bLoading = false;
      this.checkbox = false;
    },
    validateConfirmPaid() {
      this.bPaid = this.sAccountName !== null && this.checkbox;
    },
  },
  computed: {
    bShowOPPayable() {
      return this.$store.state.bShowOPPayable;
    },
    bAdminOPPayable() {
      return this.$store.state.bAdminOPPayable;
    },
  },
  watch: {
    bDialogConfirmationOfPaid() {
      if (this.bDialogConfirmationOfPaid) {
        this.returnToView = this.$store.state.returnToView;
        this.oItem = this.oPurchaseOrder;
        this.getAccounts();
      }
    },
    checkbox() {
      this.validateConfirmPaid();
    },
  },
};
</script>
<style scoped>
.content-title-add-raw-material-purchase-order {
  width: 100%;
}
.content-description-add-raw-material-purchase-order {
  width: 100%;
}
.title-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}
.description-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: center;
}

.content-question-add-raw-material-purchase-order {
  width: 100%;
  justify-self: center;
  display: flex;
  justify-content: center;
  padding: 10px 45px 10px 45px;
}

.question-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}
.content-btn-primary {
  width: 150px;
}
.content-btn-second {
  width: 150px;
  margin-right: 15px;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.table-raw-material {
  height: 300px;
  overflow: auto;
}
.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
.content-question-confirm {
  width: 100%;
  justify-self: start;
  display: flex;
  justify-content: start;
  /* padding: 10px 45px 10px 45px; */
}

.question-confirm {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}
/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>